<template>
  <v-container>
    <v-row justify="center" class="mt-3">
      <v-col class="text-center">
        <h4 class="text-h4 font-weight-regular">Liveness Request</h4>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col cols="12" xl="8">
        <v-card>
          <div class="grey--text text-center py-15" v-if="loading">
            <v-progress-circular indeterminate size="50" />
            <div class="caption my-2">Loading request...</div>
          </div>
          <v-card-text v-else>
            <v-simple-table v-if="currentLivenessRequest">
              <template v-slot:default>
                <tbody>
                  <tr v-if="currentLivenessRequest.userId && canShowUserInfo()">
                    <td class="text-right">User ID</td>
                    <td class="text-left">
                      <v-btn
                        outlined
                        :to="{ name: 'user.view', params: { id: currentLivenessRequest.userId } }"
                      >
                        {{ currentLivenessRequest.userId || "-" }}
                      </v-btn>
                    </td>
                  </tr>

                  <tr v-for="item in items" :key="item.name">
                    <td class="text-right">{{ item.name }}</td>
                    <td class="text-left">
                      {{ item.value(currentLivenessRequest) }}
                    </td>
                  </tr>

                  <tr v-if="canShowFaceRequest()">
                    <td class="text-right">Request Image</td>
                    <td class="text-left">
                      <v-btn
                        outlined
                        :to="{
                          name: 'request-image.single',
                          params: { md5sum: currentLivenessRequest.imageId },
                        }"
                      >
                        {{ currentLivenessRequest.imageId || "-" }}
                      </v-btn>
                    </td>
                  </tr>

                  <tr v-if="currentLivenessRequest.faces">
                    <td class="text-right">Faces</td>
                    <td class="text-left">
                      <div v-for="(face, i) in currentLivenessRequest.faces" :key="i">
                        <b>Frame {{ i + 1 }}</b>
                        &mdash;
                        <span class="ml-1">{{ face.label }}</span>
                        &mdash;
                        {{
                          Math.round(
                            face.label === "SPOOF"
                              ? 100 * face.spoofConfidence
                              : 100 * face.liveConfidence
                          )
                        }}% sure.
                      </div>
                    </td>
                  </tr>
                  <tr v-if="currentLivenessRequestImage">
                    <td class="text-right">Image</td>
                    <td class="text-left">
                      <media-image
                        :data="currentLivenessRequestImage.imageBase64"
                        :mime="currentLivenessRequestImage.mimeType"
                      />
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from "moment";
import MediaImage from "@/views/components/Media";
import { FACE_LIVENESS_REQUEST_SINGLE, FACE_REQUEST_IMAGE_SINGLE } from "@/constants/api";

export default {
  name: "LivenessRequest",
  metaInfo: { title: "Face Liveness Request" },
  components: {
    MediaImage,
  },
  data() {
    return {
      loading: false,
      currentLivenessRequest: null,
      currentLivenessRequestImage: null,
      items: [
        { name: "Request Id", value: (item) => item.requestId },
        { name: "Label", value: (item) => item.label },
        { name: "Model Name", value: (item) => item.modelName },
        { name: "Model Version", value: (item) => item.modelVersion },
        { name: "Confidence", value: (item) => Math.round(100 * item.confidence) + "%" },
        { name: "Fake Label Ratio", value: (item) => item.fakeLabelRatio },
        { name: "Frames Processed", value: (item) => item.framesProcessed },
        { name: "Processing Time", value: (item) => item.processingTime.toFixed(3) + " seconds" },
        {
          name: "Requested At",
          value: (item) => moment(item.createdAt).format("DD MMM, YYYY \n hh:mm:ss A"),
        },
      ],
    };
  },
  mounted() {
    if (this.$route.params.requestId) {
      this.loading = true;
      this.fetchLivenessRequest(this.$route.params.requestId)
        .then(this.fetchFaceImage)
        .finally(() => (this.loading = false));
    }
  },
  methods: {
    fetchLivenessRequest(requestId) {
      return this.$axios
        .get(`${FACE_LIVENESS_REQUEST_SINGLE}?requestId=${requestId}`)
        .then((response) => {
          if (response.data.code === 0) {
            this.currentLivenessRequest = response.data.data;
          } else {
            throw new Error(response.data.message);
          }
        })
        .catch((err) => {
          this.$iziToast.showError(err);
          this.$router.back();
        });
    },
    fetchFaceImage() {
      if (this.currentLivenessRequest.imageId) {
        this.$axios
          .get(`${FACE_REQUEST_IMAGE_SINGLE}?md5sum=${this.currentLivenessRequest.imageId}`)
          .then((response) => {
            if (response.data.code === 0) {
              this.currentLivenessRequestImage = response.data.data;
            } else {
              console.log("Image not found!");
            }
          });
      }
    },
  },
};
</script>

<style scoped></style>
